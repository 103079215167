$formSelector: ".#{$prefix}form" !default;
$fieldsetSelector: ".#{$prefix}form__fieldset" !default;
$formFieldsSelector: ".#{$prefix}fields" !default;
$fieldSelector: ".#{$prefix}field" !default;
$labelSelector: ".#{$prefix}field__label" !default;
$legendSelector: ".#{$prefix}field__legend" !default;
$fieldControlsSelectorWrapperSelector: ".#{$prefix}field__controls-wrapper" !default;
$fieldControlsSelector: ".#{$prefix}field__controls" !default;
$fieldIconSelector: ".#{$prefix}field__icon" !default;
$fieldValidationMessageSelector: ".#{$prefix}field__error" !default;
$fieldElementSelector: ".#{$prefix}field__element" !default;

$fieldBorderRadius: var(--border-radius-sharp) !default;
#{$formSelector} {
  /*
    #{$formFieldsSelector}{
        display: flex;
        gap: var(--margin-l);
        flex-wrap: wrap;
    }
    */
  #{$fieldSelector} {
    margin-top: calc(var(--margin-l) * 1.2);

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    @if (mixin-exists(hook-form-field-in-form)) {
      @include hook-form-field-in-form();
    }
  }

  &--errors-absolute #{$fieldSelector} {
    margin-bottom: var(--margin-l);
  }

  @if (mixin-exists(hook-form)) {
    @include hook-form();
  }
}

#{$fieldsetSelector} {
  --fieldset-field-gap: var(--margin-xl);

  width: 100%;
  flex: 100%;
  margin-top: var(--margin-2xl);
  margin-bottom: var(--margin-2xl);
  border-radius: var(--border-radius-s);
  border: 1px solid var(--c-gray-2);
  box-shadow: var(--box-shadow-1);
  background: var(--c-bg-1);
  padding: var(--margin-l);
  @include transition(padding, 150ms);

  // no auto margin top if first element
  * > &:first-child {
    margin-top: 0;
  }
  &.has-error {
    border-color: var(--c-danger);
  }

  &.active {
    padding-bottom: var(--margin-xl);
  }

  &--plain,
  &.plain,
  &.appearance-none {
    border: none;
    box-shadow: none;
    background: none;
    padding: 0;
  }

  &-title {
    font-size: var(--font-2xl);
    font-weight: 400;
    color: var(--c-gray-5);
    margin-bottom: 0;
    @include transition(all, 150ms);

    .we-icon:not(.expand-icon i) {
      margin-inline-end: var(--margin-s);
    }

    @include whenSmallerThan(s) {
      font-size: var(--font-xl);
      margin-bottom: var(--margin-l);
      padding-bottom: calc(var(--margin-m) * 1.5);
    }

    .expand-icon {
      @include transition(transform, 150ms);
    }

    &:hover {
      .expand-icon {
        transform: rotate(10deg);
        [dir="rtl"] & {
          transform: rotate(-10deg);
        }
      }
    }
  }

  &.active #{$fieldsetSelector}-title {
    margin-bottom: var(--margin-l);
    padding-bottom: calc(var(--margin-m) * 1.5);
    border-bottom: 1px solid var(--c-gray-2);

    .expand-icon {
      transform: rotate(90deg);

      [dir="rtl"] & {
        transform: rotate(-90deg);
      }
    }

    &:hover {
      .expand-icon {
        transform: rotate(80deg);
        [dir="rtl"] & {
          transform: rotate(-80deg);
        }
      }
    }
  }

  &-content {
    &[class*="-per-row"] {
      display: flex;
      flex-wrap: wrap;
      gap: var(--fieldset-field-gap);

      #{$fieldSelector} {
        margin-top: 0;
        margin-bottom: var(--margin-m);
      }

      @mixin _rowFieldSize($count, $break1: xl, $break2: l, $finalBreak: s) {
        $numGaps: $count - 1;

        @if ($numGaps > 0) {
          flex: 0
            0
            calc(
              100% /#{$count} -
                ((#{$numGaps}) * var(--fieldset-field-gap)) /#{$count}
            );
        } @else {
          flex: 0 0 100%;
        }
        @include whenSmallerThan($break1) {
          $smallCount: $count - 1; // minus one field per row on this break point
          $numGaps: $smallCount - 1;

          @if ($numGaps > 0) {
            flex: 0
              0
              calc(
                100% /#{$smallCount} -
                  ((#{$numGaps}) * var(--fieldset-field-gap)) /#{$smallCount}
              );
          } @else {
            flex: 0 0 100%;
          }
        }

        @include whenSmallerThan($break2) {
          $smallerCount: $count - 2; // minus two field per row on this break point
          $numGaps: $smallerCount - 1;

          @if ($numGaps > 0) {
            flex: 0
              0
              calc(
                100% /#{$smallerCount} -
                  ((#{$numGaps}) * var(--fieldset-field-gap)) /#{$smallerCount}
              );
          } @else {
            flex: 0 0 100%;
          }
        }

        @include whenSmallerThan($finalBreak) {
          $smallestCount: 1; // only one field per row on the final break point
          $numGaps: $smallestCount - 1;

          @if ($numGaps > 0) {
            flex: 0
              0
              calc(
                100% /#{$smallestCount} -
                  ((#{$numGaps}) * var(--fieldset-field-gap)) /#{$smallestCount}
              );
          } @else {
            flex: 0 0 100%;
          }
        }
      }

      @for $i from 2 through 5 {
        &.max-#{$i}-per-row {
          > #{$fieldSelector} {
            @if ($i > 3) {
              @include _rowFieldSize($i, xl, l);
            }

            @if ($i <= 3) {
              @include _rowFieldSize($i, l, m);
            }

            &.full-row-width {
              flex: 100%;
              @include whenSmallerThan(xl) {
                flex: 100%;
              }
            }
          }
        }
      }

      // break points
      &.max-3-per-row {
        @include whenSmallerThan(l) {
          #{$fieldSelector} {
            @include _rowFieldSize(2);
          }
        }

        @include whenSmallerThan(s) {
          #{$fieldSelector} {
            @include _rowFieldSize(1);
          }
        }
      }
    }
  }

  &__collapsible-footer {
    // height of the footer close ui
    height: calc(var(--font-m) + 2 * var(--margin-m));
    position: static;
    width: 100%;

    .footer-close-ui {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: var(--margin-m) var(--margin-l);
      inset-inline-start: 0;
      background: var(--c-gray-1);
      border-top: 1px solid var(--c-gray-2);
      display: flex;
      align-items: center;
    }
  }
}
@mixin inlineLabelField() {
  display: flex;
  gap: var(--margin-l);

  #{$labelSelector} {
    padding-top: 0.35em;
    width: clamp(140px, 10%, 200px);
  }
}

#{$fieldSelector} {
  position: relative;
  border-radius: $fieldBorderRadius;
  @include whenLargerThan(s) {
    // break on element own break point with js, and on screen size
    &--label-inline:not(.break-s) {
      @include inlineLabelField();
    }
  }

  &--force-label-inline {
    @include inlineLabelField();
  }

  @mixin we-form-placeholder-style {
    color: var(--c-inverse);
    font-weight: 400;
    opacity: 0.7;

    @if (mixin-exists(hook-form-field-placeholder)) {
      @include hook-form-field-placeholder();
    }
  }

  @mixin we-form-placeholder-style-minimal {
    color: var(--c-inverse);
    font-weight: 400;
    opacity: 0.7;

    @if (mixin-exists(hook-form-field-placeholder-minimal)) {
      @include hook-form-field-placeholder-minimal();
    }
  }

  @mixin we-form-placeholder-style-minimal-inverse {
    color: var(--c-bg-4);
    font-weight: 400;
    opacity: 0.7;

    @if (mixin-exists(hook-form-field-placeholder-minimal-inverse)) {
      @include hook-form-field-placeholderhook-form-field-placeholder-minimal-inverse(

      );
    }
  }

  ::placeholder,
  .pseudo-placeholder {
    @include we-form-placeholder-style;
  }

  &--style-minimal {
    background-color: transparent;
    ::placeholder,
    .pseudo-placeholder {
      @include we-form-placeholder-style-minimal;
    }
  }

  &--style-minimal-inverse {
    background-color: transparent;

    ::placeholder,
    .pseudo-placeholder {
      @include we-form-placeholder-style-minimal-inverse;
    }
  }

  &--style-none {
    background-color: transparent;
    ::placeholder,
    .pseudo-placeholder {
      @include we-form-placeholder-style-minimal;
    }
  }


  #{$labelSelector} {
    display: flex;
    gap: var(--margin-xs);
    align-items: center;
    justify-content: flex-start;
    margin-bottom: calc(var(--margin-s));
    font-size: var(--font-m);

    @if (mixin-exists(hook-form-field-label)) {
      @include hook-form-field-label();
    }
  }

  // checkbox
  #{$labelSelector}--checkbox {
    position: relative;
    cursor: pointer;
    margin-top: var(--margin-m);

    // label text
    .field__label-content {
      display: block;
      position: relative;
      margin-inline-start: 1.55em;
      font-size: var(--font-m);


      .label-text {
        display: block;
      }

      &:before {
        content: " ";
        position: absolute;
        inset-inline-end: calc(100% + 0.25em);
        height: 1.2em;
        width: 1.2em;
        border: 1px solid var(--c-gray-2);
        border-radius: $fieldBorderRadius;
        background-color: var(--c-bg);
        @include transition(all, 95ms, ease-in-out);
      }

      &:after {
        content: " ";
        text-align: center;
        position: absolute;
        top: 0.125em;
        inset-inline-end: calc(100% + 0.3em);
        height: 1.2em;
        width: 1.2em;
        font-size: 0.9rem;
        background-color: transparent;
        color: transparent;
        @include transition(all, 275ms, ease-out, 120ms);
      }
    }

    input:checked + .field__label-content {
      &:before {
        border-color: var(--c-lead);
        background-color: var(--c-lead);
      }
      &:after {
        content: "\2713";
        color: var(--c-bg);
      }
    }

    &#{$labelSelector}--theme-danger {
      input:checked + .field__label-content {
        &:before {
          border-color: var(--c-danger);
          background-color: var(--c-danger);
        }
        &:after {
          color: var(--c-bg);
        }
      }
    }

    &#{$labelSelector}--theme-lead {
      input:checked + .field__label-content {
        &:before {
          border-color: var(--c-lead);
          background-color: var(--c-lead);
        }
        &:after {
          color: var(--c-bg);
        }
      }
    }

    &#{$labelSelector}--theme-alt {
      input:checked + .field__label-content {
        &:before {
          border-color: var(--c-alt);
          background-color: var(--c-alt);
        }
        &:after {
          color: var(--c-bg);
        }
      }
    }

    &#{$labelSelector}--theme-warning {
      input:checked + .field__label-content {
        &:before {
          border-color: var(--c-warning);
          background-color: var(--c-warning);
        }
        &:after {
          color: var(--c-bg);
        }
      }
    }

    &#{$labelSelector}--theme-success {
      input:checked + .field__label-content {
        &:before {
          border-color: var(--c-success);
          background-color: var(--c-success);
        }
        &:after {
          color: var(--c-bg);
        }
      }
    }

    // checkbox element
    #{$fieldElementSelector} {
      display: none;
    }
  }

  // radio
  #{$fieldControlsSelector}--radio {
    display: flex;
    flex-wrap: wrap;
    gap: var(--margin-m);
    margin-top: var(--margin-m);

    &#{$fieldControlsSelector}--stacked {
      display: block;
      #{$labelSelector} {
        margin-bottom: var(--margin-m);

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    #{$labelSelector} {
      position: relative;
      cursor: pointer;
      display: flex;
      margin-inline-end: var(--margin-l);

      // label text
      .field__label-content {
        position: relative;
        margin-inline-start: 1.5em;
        font-size: var(--font-m);

        &:before {
          content: " ";
          position: absolute;
          top: 0;
          inset-inline-end: calc(100% + 0.25em);
          height: 1.2em;
          width: 1.2em;
          border: 1px solid var(--c-gray-2);
          border-radius: var(--border-radius-round);
          background-color: var(--c-bg);
        }

        &:after {
          content: " ";
          position: absolute;
          top: 4px;
          inset-inline-end: calc(100% + 0.25em + 4px);
          height: calc(1.2em - 8px);
          width: calc(1.2em - 8px);
          background-color: transparent;
          border-radius: var(--border-radius-round);
          @include transition(background-color, 150ms, ease-in-out);
        }
      }

      input:checked + .field__label-content {
        &:after {
          background-color: var(--c-lead);
        }
      }

      // checkbox element
      #{$fieldElementSelector} {
        display: none;
      }
    }
  }

  #{$fieldControlsSelectorWrapperSelector} {
  }

  #{$fieldControlsSelector} {
    position: relative;
    font-size: var(--font-m);
    background-color: var(--c-bg);
    border: 1px solid var(--c-gray-2);
    border-radius: $fieldBorderRadius;

    @include transition(border-color, 75ms, "ease-in");

    &--style-minimal {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid var(--c-inverse);
      background-color: transparent;
      border-radius: 0;
    }

    &--style-none {
      border: none;
      background-color: transparent;
      border-radius: 0;
    }

    &--style-minimal-inverse {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid var(--c-bg);
      background-color: transparent;
      border-radius: 0;
    }

    &--checkbox,
    &--radio {
      border: none;
      border-radius: 0;
      background-color: transparent;
    }

    &--toggle {
      border: none;
      border-radius: 0;
      background-color: transparent;
    }

    &:focus-within,
    &--error:focus-within {
      border-color: var(--c-lead-2);
    }

    &--error {
      border-color: var(--c-error);
      @if (mixin-exists(hook-form-field-error)) {
        @include hook-form-field-error();
      }
    }

    &--success,
    &--success:focus-within {
      border-color: var(--c-success);
    }

    textarea {
      margin-top: 0;
      margin-bottom: 0.2em;
      min-height: 80px;
    }

    .character-counter {
      border-top: 2px solid var(--c-gray-4);
      padding: 0.2rem 2rem;
    }

    @if (mixin-exists(hook-form-form-control)) {
      @include hook-form-form-control();
    }
  }

  #{$fieldControlsSelector}--units {
    border: none;

    input {
      border-top: 1px solid var(--c-gray-2);
      border-bottom: 1px solid var(--c-gray-2);
    }

    .#{$prefix}button:first-child {
      [dir="ltr"] & {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      [dir="rtl"] & {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .#{$prefix}button:last-child {
      [dir="ltr"] & {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      [dir="rtl"] & {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  // icons
  #{$fieldIconSelector} {
    position: absolute;
    inset-inline-start: 0.4em;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    &--end {
      inset-inline-start: auto;
      inset-inline-end: 0.4em;
    }
  }

  // icons in field size variations
  &--s #{$fieldIconSelector} {
    top: 50%;
    transform: translateY(-50%);
  }

  &--l #{$fieldIconSelector} {
    top: 50%;
    transform: translateY(-50%);
  }

  #{$fieldElementSelector} {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    box-shadow: none;
    appearance: none;
    outline: none;
    background: transparent;
    flex: 100%;
    width: 100%;
    min-width: 200px;
    padding: 0.6em 2em;
    border-radius: $fieldBorderRadius;
    min-height: 2.075em; // align with button height

    &--no-min-width {
      min-width: 0;
    }

    &[disabled] {
      background-color: var(--c-gray-2);
      cursor: not-allowed;
    }

    &--style-minimal {
      background-color: transparent;
    }

    &--style-minimal-inverse {
      background-color: transparent;
      color: var(--c-bg);
    }

    &:autofill,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px var(--c-bg) inset;
      color: var(--c-inverse);
    }

    &--style-minimal-inverse:-webkit-autofill,
    &--style-minimal-inverse:-webkit-autofill:hover,
    &--style-minimal-inverse:-webkit-autofill:focus,
    &--style-minimal-inverse:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px var(--c-inverse) inset;
      color: var(--c-bg);
    }

    &:focus {
      -webkit-appearance: none;
      -moz-appearance: none;
      border: none;
      outline: none;
      box-shadow: none;
      appearance: none;

      &:autofill,
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px var(--c-bg) inset;
        color: var(--c-inverse);
      }
    }

    &--style-minimal-inverse:focus {
      -webkit-appearance: none;
      -moz-appearance: none;
      border: none;
      outline: none;
      box-shadow: none;
      appearance: none;

      &:autofill,
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px var(--c-inverse) inset;
        color: var(--c-bg);
      }
    }

    &--select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: transparent;
      border: none;
      box-shadow: none;
      appearance: none;
      outline: none;
      width: 100%;
      flex: 100%;
      padding: 0.63em 2em; // this 0.63 works. but no idea why it is needed. if this does anything strange again, implement constant height for all fields

      option {
        background-color: var(--c-bg);
        border: none;

        &:hover,
        &:active {
          background-color: var(--c-lead);
          color: var(--c-bg);
        }
      }

      @if (mixin-exists(hook-form-field-element-select)) {
        @include hook-form-field-element-select();
      }
    }

    &.on-off-toggle {
      min-width: 0;

      .toggle__controls {
        margin: 0;
        padding-inline: var(--margin-s);
      }
    }
    &--s {
      min-height: 1em; // align with button height
      flex: 100%;
      width: 100%;
      padding: 0.4em 2em;
      font-size: var(--font-s);
    }

    &--l {
      flex: 100%;
      width: 100%;
      padding: 0.6em 3em;
      font-size: var(--font-l);
    }

    #{$fieldElementSelector}[type="number"] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: none;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    @if (mixin-exists(hook-form-field-element)) {
      @include hook-form-field-element();
    }
  }


  #{$fieldElementSelector}--no-value#{$fieldElementSelector}--style-minimal {
    ::placeholder,
    .pseudo-placeholder {
      @include we-form-placeholder-style-minimal;
    }
  }

  #{$fieldElementSelector}--no-value#{$fieldElementSelector}--style-minimal-inverse {
    ::placeholder,
    .pseudo-placeholder {
      @include we-form-placeholder-style-minimal-inverse;
    }
  }

  #{$fieldElementSelector}--color {
    padding: 0.535em 2em; // if this acts strangely again, apply constant height to fields
  }

  #{$fieldElementSelector}-color-preview {
    height: 1.2rem;
    width: 1.3rem;
    border-radius: var(--border-radius-round);
  }

  #{$fieldElementSelector}-placeholder {
    @include we-form-placeholder-style;
    @if (mixin-exists(hook-form-field-placeholder)) {
      @include hook-form-field-placeholder();
    }
  }

  #{$fieldElementSelector}-color-input-inner {
    background-color: transparent;
  }

  #{$formSelector}--errors-absolute & #{$fieldValidationMessageSelector} {
    position: absolute;
    top: calc(100% + var(--margin-min));
  }

  #{$legendSelector} {
    color: var(--c-gray-5);
    font-size: var(--font-s);
    margin-top: var(--margin-s);
    display: inline-block;
  }

  #{$fieldValidationMessageSelector} {
    display: flex;
    color: var(--c-danger);
    font-size: var(--font-s);
    margin-top: var(--margin-min);
    @if (mixin-exists(hook-form-field-error-message)) {
      @include hook-form-field-error-message();
    }

    &.error-absolute-position {
      position: absolute;
      top: calc(100% + var(--margin-min));
    }
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: none;
    box-shadow: none;
    appearance: none;

    &:autofill,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px var(--c-bg) inset;
      color: var(--c-inverse);
    }

    &:focus {
      -webkit-appearance: none;
      -moz-appearance: none;
      border: none;
      outline: none;
      box-shadow: none;
      appearance: none;

      &:autofill,
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px var(--c-bg) inset;
        color: var(--c-inverse);
      }
    }

    &--style-minimal-inverse:focus {
      -webkit-appearance: none;
      -moz-appearance: none;
      border: none;
      outline: none;
      box-shadow: none;
      appearance: none;

      &:autofill,
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px var(--c-inverse) inset;
        color: var(--c-bg);
      }
    }
  }

  @if (mixin-exists(hook-field)) {
    @include hook-field();
  }
}
