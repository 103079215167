/**
 * Order is very important.
 * 1st: SCSS variable overrides
 * 2nd: build import
 * 3rd: component hooks
 * 4th: component import
 * 5th: CSS variables declaration/override (build includes :root level declaration)
 * 6th: other custom CSS
 */

// font - copied from google font
//itallic - enable when we need it
/*
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtE6F15M.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWvU6F15M.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWu06F15M.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWxU6F15M.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0330, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2034-2037, U+2057, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2102, U+210A-210E, U+2110-2112, U+2115, U+2119-211D, U+2124, U+2128, U+212C-212D, U+212F-2131, U+2133-2138, U+213C-2140, U+2145-2149, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B6, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqW106F15M.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8B1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA88, U+1FA90-1FABD, U+1FABF-1FAC5, U+1FACE-1FADB, U+1FAE0-1FAE8, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWt06F15M.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuU6F.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
*/
/* cyrillic-ext */
/*
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
*/
/* cyrillic */
/*
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
*/
/* hebrew */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* math */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTVOmu1aB.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0330, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2034-2037, U+2057, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2102, U+210A-210E, U+2110-2112, U+2115, U+2119-211D, U+2124, U+2128, U+212C-212D, U+212F-2131, U+2133-2138, U+213C-2140, U+2145-2149, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B6, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTUGmu1aB.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8B1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA88, U+1FA90-1FABD, U+1FABF-1FAC5, U+1FACE-1FADB, U+1FAE0-1FAE8, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

$global-prefix: "";

//$button-height: 2.25em !default;
//$button-padding-horizontal: 1.2em !default;
//$button-padding-horizontal-large: 1.4em !default;
//$button-padding-horizontal-slim: 0.2em !default;
//$button-border-thickness: 1px !default;

//$button-height-small             : 2.0em !default;
//$button-padding-horizontal-small : 1.0em !default;

//$button-height-large             : 2.4em !default;

// field element selector
@mixin hook-form-field-element {

}

// 2nd: build import
@import "../../../../../../otherSrc/client/we/scss/build";

// our custom component // * NOTE: we can not include partial SCSS files inside the client forlder, atm.

// 3rd: component hooks

$form-element-height: 46px;

.#{$prefix}button-group {

}

@mixin hook-button {

  .layout-lp & {
    // general - size and no border radius
    border-radius: 0;
    height: calc(var(--base-margin) * 46);
    min-height: calc(var(--base-margin) * 46);
    padding-inline: var(--margin-2xl);
    padding-block: 0;
    line-height: $form-element-height;
    font-weight: 600;

    // general - L size
    &--l, &--large,&--lp-l {
      padding-inline-end   : $button-padding-horizontal-large;
      padding-inline-start : $button-padding-horizontal-large;
      height               : 68px;
      min-height           : 68px; // for flex column
      font-size            : var(--font-xl);

      & .#{$prefix}button__icon {
        height:  $font-3xl;
        width:   $font-3xl;
        background-size: contain;
        background-position: center center;
        margin-inline-end: $margin-s;

        &:only-child {
          margin-inline-end: 0;
        }
      }

      & .#{$prefix}button__icon-end {
        height:  $font-3xl;
        width:   $font-3xl;
        background-size: contain;
        background-position: center center;
        margin-inline-start: $margin-s;

        &:only-child {
          margin-inline-start: 0;
        }
      }

      @media (max-width: 1024px) {
        height: calc(var(--base-margin) * 46)!important;
        min-height: calc(var(--base-margin) * 46)!important;
        padding-inline: var(--margin-2xl);

        & .#{$prefix}button__icon {
          height:  $font-3xl;
          width:   $font-3xl;
          background-size: contain;
          background-position: center center;
          margin-inline-end: $margin-s;

          &:only-child {
            margin-inline-end: 0;
          }
        }

        & .#{$prefix}button__icon-end {
          height:  $font-3xl;
          width:   $font-3xl;
          background-size: contain;
          background-position: center center;
          margin-inline-start: $margin-s;

          &:only-child {
            margin-inline-start: 0;
          }
        }

      }
    }


    // main theme - alt
    &--alt {
      background-color: var(--c-alt);
      border-color: var(--c-alt);
      color: var(--c-lead);
      &:hover {
        background-color: var(--c-alt-4);
        border-color: var(--c-alt-4);
        color: var(--c-lead);
      }
    }


    // thheme lead
    &--lead {
      background-color: var(--c-lead);
      border-color: var(--c-lead);
      color: var(--c-bg);
      &:hover {
        background-color: var(--c-lead-4);
        border-color: var(--c-lead-4);
        color: var(--c-bg);
      }
    }

    &--lead-inverse {
      background-color: var(--c-lead);
      border-color: var(--c-alt);
      color: var(--c-bg);
      &:hover {
        background-color: var(--c-alt);
        border-color: var(--c-alt);
        color: var(--c-bg);
      }
    }



    // button theme - alt (orange with blue text, dark hover)
    &--alt-lead-inverse {
      background-color: transparent;
      border-color: var(--c-alt);
      color: var(--c-lead);
      &:hover {
        background-color: var(--c-alt-4);
        border-color: var(--c-alt-4);
        color: var(--c-bg);
      }
    }
    &--lead-5 {
      background-color: var(--c-lead-5);
      color: var(--c-bg);

      &:hover {
        background-color: var(--c-lead-4);
        border-color: var(--c-lead);
      }
    }

    // button theme - lead

    // button theme - lead-inverse (blue and orange border, white text)
  }

}

// form
@mixin hook-form-field-placeholder {
  .layout-lp & {
    color: var(--c-gray-4);
    opacity: 1;
    font-weight: 400;
  }
  color: var(--c-gray-4);
  opacity: 1;
  font-weight: 400;
}



@mixin hook-form-field-error-message {
  .layout-lp & {
    &.error-absolute-position {
      top: calc(100% + var(--margin-xs));
    }
  }

}


@mixin hook-form-field-element {
  .layout-lp &, .layout-lp &--select {
    padding-block: var(--margin-m);
    min-height: calc(#{$form-element-height} - 2px);
  }
}

@mixin hook-form-form-control {
  .layout-lp & {
    font-size: var(--font-s);
    border-radius: 0;
    min-height: $form-element-height;
  }

}

@mixin hook-field {
  .layout-lp & {
    // checkbox margin fix
    .#{$prefix}field__label--checkbox {
      .field__label-content {
        padding-inline-start: var(--margin-s);

        &:after {
          top: 0;
        }
      }
    }

    // radio
    .#{$prefix}field__label--radio {
      .field__label-content {
        padding-inline-start: var(--margin-s);
      }
    }
  }

}

@mixin hook-modal {
  &__inner {
    border-radius: 0;
    border: 1px solid var(--c-gray-1);
  }
}

$font-jumbo                  : var(--font-jumbo) !default;
$font-hero                  : var(--font-hero) !default;

$font-size-map             : (
        "-min": $font-min,
        "-xs": $font-xs,
        "-s": $font-s,
        "-m": $font-m,
        "-l": $font-l,
        "-xl": $font-xl,
        "-2xl": $font-2xl,
        "-3xl": $font-3xl,
        "-max": $font-max,
        "-jumbo": $font-jumbo,
        "-hero": $font-hero,
);

// 4th: component import
@import "../../../../../../otherSrc/client/we/scss/components";

//#DBA800

// 5th: CSS variables declaration/override
body,
:root {
  // colors
  --c-core-lead-1           : #4D84FF;
  --c-core-lead-2           : #1352DE;
  --c-core-lead             : #001871;
  --c-core-lead-4           : #001257;
  --c-core-lead-5           : #000E42;
  --c-core-alt-1            : #FFAE59;
  --c-core-alt-2            : #FF9323;
  --c-core-alt              : #FF8200;
  --c-core-alt-4            : #E57500;
  --c-core-alt-5            : #C86600;

  --c-comp-lead-1           : #00EDDE;
  --c-comp-lead-2           : #00D9CB;
  --c-comp-lead             : #00BFB3;
  --c-comp-lead-4           : #00A69B;
  --c-comp-lead-5           : #008C83;

  // --c-comp-alt-1            : #FEB276;
  // --c-comp-alt-2            : #fd9644;
  // --c-comp-alt              : #fa8231;
  //--c-comp-alt-4            : #f86806;
  //--c-comp-alt-5            : #C65305;

  --c-core-inverse          : #222222;
  --c-text                  : #2a2a2a;
  --c-core-bg-1             : #ffffff;
  --c-core-bg-2             : #ffffff;
  --c-core-bg               : #ffffff;
  --c-core-bg-4             : #EDEDED;
  --c-core-bg-5             : #E8E8E8;
  --c-gray-1                : #F2F1F0;
  --c-gray-2                : #D9D8D7;
  --c-gray                  : #C6C5C4;
  --c-gray-3                : var(--c-gray);
  --c-gray-4                : #8A8988;
  --c-gray-5                : #2E2E2E;

  --c-success-1             : #6DFFB8;
  --c-success-2             : #3ad985;
  --c-success               : #07a652;
  --c-success-4             : #068d46;
  --c-success-5             : #00731F;
  --c-success-inverse       : var(--c-bg);
  --c-warning               : #ee8735;
  --c-warning-inverse       : var(--c-bg);
  --c-danger                : #eb3b5a;
  --c-danger-inverse        : var(--c-bg);
  --c-error                 : var(--c-danger);
  --c-error-inverse         : var(--c-danger-inverse);

  // color aliases
  --c-lead                  : var(--c-core-lead);
  --c-lead-1                : var(--c-core-lead-1);
  --c-lead-2                : var(--c-core-lead-2);
  --c-lead-4                : var(--c-core-lead-4);
  --c-lead-5                : var(--c-core-lead-5);
  --c-alt-1                 : var(--c-core-alt-1);
  --c-alt-2                 : var(--c-core-alt-2);
  --c-alt                   : var(--c-core-alt);
  --c-alt-4                 : var(--c-core-alt-4);
  --c-alt-5                 : var(--c-core-alt-5);
  --c-inverse               : var(--c-core-inverse);
  --c-bg-4                  : var(--c-core-bg-4);
  --c-bg-5                  : var(--c-core-bg-5);
  --c-bg                    : var(--c-core-bg);
  --c-bg-2                  : var(--c-core-bg-2);
  --c-bg-1                  : var(--c-core-bg-1);
}

body .layout-lp, {
  --font-min                : calc(10 * var(--base-font-size));
  --font-xs                 : calc(12 * var(--base-font-size));
  --font-s                  : calc(14 * var(--base-font-size));
  --font-m                  : calc(16 * var(--base-font-size));
  --font-l                  : calc(18 * var(--base-font-size));
  --font-xl                 : calc(20 * var(--base-font-size));
  --font-2xl                : calc(25 * var(--base-font-size));
  --font-3xl                : calc(32 * var(--base-font-size));
  --font-max                : calc(38 * var(--base-font-size));
  --font-jumbo              : calc(52 * var(--base-font-size));
  --font-hero              : var(--font-jumbo);

  --margin-xs               : calc(2 * var(--base-margin));
  --margin-s                : calc(5 * var(--base-margin));
  --margin-m                : calc(12 * var(--base-margin));
  --margin                  : var(--margin-m);
  --margin-l                : calc(24 * var(--base-margin));
  --margin-xl               : calc(38 * var(--base-margin));
  --margin-2xl              : calc(42 * var(--base-margin));
  --margin-max              : calc(76 * var(--base-margin));

  a {
    text-underline-offset: var(--margin-s);
  }

}

// 6th: other custom CSS
*,
*::before,
*::after {
  box-sizing: border-box;
}

//firefox
* {
  scrollbar-color: var(--c-lead-2) var(--c-gray);
  scrollbar-width: thin;
}

// chrome
::-webkit-scrollbar-track {
  background-color: var(--c-gray);
}

// 6th: custom css
html,
body {
  background: var(--c-bg);
  font-family: 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  *,
  input,
  button,
  textarea,
  select,
  label,
  p {
    font-family: 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  }

  .jumbo, .text-jumbo {
    font-size: var(--font-hero);
    font-weight: 700;
    letter-spacing: -0.52px;

    @media (max-width: 1024px) {
      font-size: var(--font-3xl);
      font-weight: 700;
      letter-spacing: -0.32px;
    }
  }

  h1,.h1, *.h1 {
    font-size: var(--font-max);
    font-weight: 700;
    letter-spacing: -0.38px;

    @media (max-width: 1024px) {
      font-size: var(--font-2xl);
      font-weight: 700;
      letter-spacing: -0.25px;
    }
  }

  h2, .h2, *.h2 {
    font-size: var(--font-3xl);
    font-weight: 700;
    letter-spacing: -0.32px;

    @media (max-width: 1024px) {
      font-size: var(--font-2xl);
      font-weight: 700;
      letter-spacing: -0.25px;
    }
  }

  h3, .h3, *.h3 {
    font-size: var(--font-2xl);
    font-weight: 700;
    letter-spacing: -0.25px;

    @media (max-width: 1024px) {
      font-size: var(--font-xl);
      font-weight: 700;
      letter-spacing: -0.20px;
    }
  }

  h4, .h4, *.h4 {
    font-size: var(--font-xl);
    font-weight: 700;
    letter-spacing: -0.2px;

  }

  .lp-section-title {
    font-weight: bold;
    color: var(--c-lead);
    font-size: var(--font-3xl);
    margin-bottom: var(--margin-xl);

    @media (max-width:1024px) {
      text-align: center;
      line-height: 0.9;
      font-size: var(--font-2xl);
      margin-bottom: var(--margin-l);
    }
  }

}


